export default {

  assets: {
    cast_in: 'Cast in',
    cancel_text: 'Are you sure you want to archive {name}?',
    delete_error: 'An error occurred while deleting this asset. There are probably data linked to it. Are you sure this asset type has no task linked to it?',
    delete_for_selection: 'Delete the selected asset | Delete the {nbSelectedAssets} selected assets',
    delete_text: 'Are you sure you want to remove {name} from your database?',
    edit_fail: 'Saving failed, it may be because an asset with a similar name already exists.',
    edit_success: 'Asset {name} successfully edited.',
    edit_title: 'Edit asset',
    empty_list: 'There is no asset in the production. What about creating some?',
    empty_list_client: 'There is no asset in this production.',
    linked: 'Linked',
    multiple_delete_error: 'An error occurred while deleting an asset. There is probably some data linked to an asset. Are you sure there is no task linked to a selected asset?',
    only_current_episode: 'Only current episode',
    new_asset: 'Create assets',
    new_assets: 'Create assets',
    new_success: 'Asset {name} successfully created.',
    no_cast_in: 'This asset is not cast in any shot.',
    no_concept: 'No concepts linked to this asset',
    number: 'asset | assets',
    restore_text: 'Are you sure you want to restore {name} from your archive?',
    restore_error: 'An error occurred while restoring this asset.',
    tasks: 'Asset tasks',
    title: 'Assets',
    fields: {
      description: 'Description',
      episode: 'Ep.',
      name: 'Name',
      production: 'Prod',
      ready_for: 'Ready for',
      time_spent: 'Time',
      type: 'Type',
      hidden_from_client: 'Displayed to client'
    }
  },

  asset_types: {
    all_asset_types: 'All asset types',
    create_error: 'An error occurred while saving this asset type. Are you sure there is no asset type with a similar name?',
    delete_text: 'Are you sure you want to remove {name} from your database?',
    delete_error: 'An error occurred while deleting this asset type. There are probably data linked to it. Are you sure this asset type has no asset linked to it?',
    edit_title: 'Edit asset type',
    include_all: 'Includes all asset task types',
    new_asset_type: 'Add an asset type',
    number: 'asset type | asset types',
    title: 'Asset Types',
    production_title: 'Asset Types Stats',
    fields: {
      name: 'Name',
      task_types: 'Workflow'
    }
  },

  backgrounds: {
    create_error: 'An error occurred while saving this background. Are you sure there is no background with a similar name?',
    delete_text: 'Are you sure you want to remove {name} from your database?',
    delete_error: 'An error occurred while deleting this background. There are probably data linked to it. Are you sure this background has no task linked to it?',
    name: 'background',
    number: 'background | backgrounds',
    edit_background: 'Edit background',
    new_background: 'Add a new background',
    title: '3D Backgrounds',
    fields: {
      is_default: 'Is default',
      name: 'Name',
      file: 'HDRi File'
    }
  },

  board: {
    title: 'Board',
    empty: 'Your kanban board is currently empty. It means no task status is configured for your user role in the production settings.',
    settings: {
      title: 'Board Status',
      visible: 'Displayed on kanban board of...',
      select_all: 'Select all',
      unselect_all: 'Unselect all'
    }
  },

  bots: {
    title: 'Bots',
    bots: 'bot | bots',
    active_bots: 'active bot | active bots',
    new_bot: 'Add a new bot',
    edit_title: 'Edit bot',
    edit_avatar_error: 'An error occurred while updating the bot avatar.',
    new_token_title: 'New API token for {name}',
    new_token_warning: 'If you generate a new token, the old one will be disabled.',
    generate: 'Generate a new token',
    copy_token_warning: 'Make sure you copy the token below. You will not be able to see it again.',
    token_copied: 'Token copied to clipboard!',
    fields: {
      name: 'Name',
      expiration_date: 'Expiration date',
      token: 'token',
    }
  },

  breakdown: {
    all_assets: 'All available assets',
    edit_label: 'Change the asset\'s label',
    empty: 'Empty casting',
    episode_casting: 'Episode casting',
    label: 'Label',
    picture_mode: 'Switch to picture mode',
    save_error: 'Error while saving casting',
    text_mode: 'Switch to text mode',
    title: 'Breakdown',
    options: {
      fixed: 'fixed',
      animate: 'animate'
    },
    remove: {
      confirm: 'Remove asset from its episode',
      error: 'There was a server error while removing asset. Please, try again. If the problem persists, contact our support team.',
      text: 'If you remove this asset from the selected episode, it will remove the assets in all the underlying shots. Are you sure you want to remove it?'
    },
    fields: {
      standby: 'STDB'
    }
  },

  chats: {
    delete_message: 'Are you sure you want to delete this message?',
    delete_message_confirm: 'Delete message',
    join: 'Join chat',
    leave: 'Leave chat',
    no_message_yet: 'No message',
    no_chat: 'You don\'t participate in any entity chat for the moment. Search an entity and join a chat from its page.',
    search_entity: 'Search for entity',
    title: 'Entity Chats',
  },

  comments: {
    add_attachment: 'Add attachment',
    add_checklist: 'Add checklist',
    add_comment: 'Leave a comment...',
    add_link: 'Add a URL link',
    add_preview: 'Add preview revisions to publish',
    attachments: 'Attachments',
    attachments_to_add: 'Add attachments',
    checklist: 'Checklist',
    change_preview: 'Change preview',
    confirm_publish: 'There is no preview files set for publishing. Are you sure you want to publish your comment?',
    confirm_publish_button: 'Post comment without publishing files',
    comment_from_client: 'Comment from client',
    edit_title: 'Edit comment',
    empty_text: 'This comment is empty',
    edit_error: 'An error occurred while editing the comment. Please contact our support team.',
    error: 'An error occurred while posting comment',
    max_retakes_error: 'You cannot add retakes anymore on this task.',
    no_attachments: 'There are no attachments for this comment.',
    no_file_attached: 'No preview attached',
    pin: 'Pin',
    pinned: 'Pinned',
    post_status: 'Post comment',
    previews: 'Preview files to publish as a new revision',
    retake: 'Retake',
    revision: 'revision',
    selected_files: 'Selected files',
    set_status_to: 'Set status to',
    task_placeholder: 'New item...',
    text: 'Text',
    unpin: 'Unpin',
    validated: 'Validated!',
    validation_required: 'Validation Required',
    fields: {
      text: 'Text',
      created_at: 'Creation date',
      task_status: 'Task status',
      person: 'Person name',
      checklist: 'Checklist',
      acknowledgements: 'Acknowledgements'
    }
  },

  concepts: {
    add_links: 'Add links to assets',
    add_new_concept: 'Add new concepts',
    add_concept: 'Add files for new concepts',
    add_concept_error: 'An error occurred while adding concept.',
    delete_for_selection: 'Delete the selected concept | Delete the {nbSelectedConcepts} selected concepts',
    empty: 'There are no concept for this production',
    multiple_delete_error: 'An error occurred while deleting a concept. There is probably some data linked to a concept. Are you sure there is no task linked to a selected concept?',
    no_concept_selected: 'No concept selected',
    title: 'Concepts',
    fields: {
      entity_type: 'Entity type',
      created_at: 'Creation date',
      updated_at: 'Update date',
      last_comment_date: 'Last comment',
      publisher: 'Publish by'
    },
    actions: {
      title: 'Links to Concept',
      empty: 'No links'
    }
  },

  custom_actions: {
    create_error: 'An error occurred while saving this custom action. Are you sure that there is no other action with the same name?',
    delete_text: 'Are you sure you want to remove custom action {name} from your database?',
    delete_error: 'An error occurred while deleting this custom action.',
    edit_title: 'Edit a custom action',
    new_custom_action: 'Add a custom action',
    number: 'custom action | custom actions',
    run_for_selection: 'Run custom action for the selected task | Run custom actions for the {nbSelectedTasks} selected tasks',
    title: 'Custom Actions',
    fields: {
      name: 'Name',
      url: 'URL',
      entity_type: 'Entity Type',
      is_ajax: 'Use AJAX'
    },
    entity_types: {
      all: 'All',
      shot: 'Shot',
      asset: 'Asset'
    }
  },

  status_automations: {
    change_ready_for: 'changes "ready for" to',
    change_status: 'changes task status to',
    create_error: 'An error occurred while saving this status automation. Are you sure that there is no other action with the same name?',
    delete_text: 'Are you sure you want to remove status automation {name} from your database?',
    delete_error: 'An error occurred while deleting this status automation. It may be linked to a project. In that case, you can\'t delete it. You have to remove it from every project first.',
    edit_title: 'Edit a status automation',
    entity_title: 'Entity type concerned by the trigger',
    number: 'status automation | status automations',
    in_title: 'Status triggering a change',
    out_title: 'The change that will be applied on trigger firing',
    new_status_automation: 'Add status automation',
    run_for_selection: 'Run status automation for selected tasks:',
    title: 'Automations',
    wrong_automation: 'This automation applies to a backward task type. It is not permitted by Kitsu (to avoid cycles). It will have no effect.',
    fields: {
      entity_type: 'Entity Type',
      in_field_type: 'Input Field Type',
      in_task_type: 'Task Type',
      in_task_status: 'Task Status',
      out_field_type: 'Trigger',
      out_task_type: 'Applied Task Type',
      out_task_status: 'Applied Task Status',
      import_last_revision: "Import Last Revision",
    },
    entity_types: {
      asset: 'Asset',
      shot: 'Shot'
    },
    field_types: {
      status: 'Status',
      ready_for: 'Ready For'
    }
  },

  departments: {
    all_asset_types: 'All departments',
    create_error: 'An error occurred while saving this department. Are you sure there is no department with a similar name?',
    delete_text: 'Are you sure you want to remove {name} from your database?',
    delete_error: 'An error occurred while deleting this department.',
    edit_title: 'Edit department',
    no_department: 'No department',
    new_departments: 'Add a department',
    number: 'department | departments',
    title: 'Departments',
    fields: {
      name: 'Name',
      color: 'Color'
    }
  },

  entities: {
    build_filter: {
      asset_type: 'Asset type',
      all_types: 'All asset types',
      assignation: 'Assignment',
      assignation_exists_for: 'Assignments exists for',
      assigned_to: 'Assigned to',
      department: 'Department',
      descriptor: 'Metadata',
      equal: 'Equal',
      in: 'In',
      is_assets_ready: 'Assets are ready',
      assets_ready: 'Ready',
      assets_not_ready: 'Not ready',
      no_assignation_for: 'No assignment exists for',
      no_filter: 'No filter',
      not_equal: 'Not equal',
      not_assigned_to: 'Not assigned to',
      status: 'Task status',
      thumbnail: 'Thumbnail presence',
      title: 'Filter on...',
      union_and: 'Match all the following filters',
      union_or: 'Match one of the following filters',
      with_thumbnail: 'With thumbnail',
      without_thumbnail: 'Without thumbnail',
      checked: 'Checked',
      not_checked: 'Not checked'
    },

    logs: {
      no_logs: 'There are no time logs for this entity.'
    },

    nb_selected_assets: '{nbSelectedAssets} asset selected | {nbSelectedAssets} assets selected',
    nb_selected_shots: '{nbSelectedShots} shot selected | {nbSelectedShots} shots selected',
    nb_selected_edits: '{nbSelectedEdits} edit selected | {nbSelectedEdits} edits selected',

    output_files: {
      extension: 'Extension',
      name: 'Name',
      no_output_files: 'There are no output files for this entity.',
      person: 'Owner',
      revision: 'Revision',
      size: 'Size',
      status: 'Status',
      task_type: 'Task type',
      type: 'Type',
    },

    preview_files: {
      extension: 'Extension',
      no_preview_files: 'There are no preview files for this entity.',
      original_file_name: 'Original File Name',
      position: 'Position',
      revision: 'Revision',
      size: 'Size',
      status: 'Status',
      task_type: 'Task type',
      uploader: 'Uploaded by'
    },

    thumbnails: {
      assets_pattern: '"AssetType AssetName" eg. Environment_Forest.',
      edits_pattern: '"EditName" eg. Trailer.',
      edits_tvshow_pattern: '"EpisodeName EditName" eg. E01_Trailer.',
      error: 'An error occurred while uploading thumbnails',
      explaination: 'Adding a thumbnail requires to set a new preview. In order to set several thumbnails at the same time, you must choose first a task type that will be used to create the new previews. The thumbnails will be set from this new preview.',
      explaination_two: 'Then you have to select the files you want to upload. To find the right entities, the file names must match the following pattern:',
      select_files: 'Select Files',
      selected_files: 'Selected Files',
      select_task_type: 'Select Task Type',
      shots_pattern: '"SequenceName ShotName" eg. SQ01_SH01.',
      title: 'Add Thumbnails',
      undefined: 'Undefined',
      undefined_pattern: 'Undefined',
      upload: 'Add Thumbnails'
    }
  },

  estimation: {
    title: 'Estimation'
  },

  episodes: {
    all_episodes: 'All',
    edit_error: 'An error occurred while saving this episode. Are you sure there is no episode with similar name?',
    delete_error: 'An error occurred while deleting this episode. There are probably data linked to it. Are you sure this episode has no sequence linked to it?',
    delete_text: 'Are you sure you want to remove {name} from your database? Every related shots and previews will be deleted. Please confirm by typing the episode name below.',
    edit_title: 'Edit episode',
    empty_list: 'There is no episode in the production. What about creating some?',
    empty_list_client: 'There is no episode in this production.',
    new_episode: 'New episode',
    no_casting: 'The episode casting is empty.',
    number: 'episode | episodes',
    restore_error: 'An error occurred while restoring given episode.',
    tasks: 'Episode tasks',
    title: 'Episodes',
    stats_title: 'Episodes Stats',
    fields: {
      name: 'name',
      description: 'description',
      placeholder: 'E01'
    },
    status: {
      'canceled': 'Canceled',
      'complete': 'Complete',
      'standby': 'Stand By',
      'running': 'Running'
    }
  },

  keyboard: {
    altdown: 'Move task selection down',
    altj: 'Select the previous preview',
    altk: 'Select the next preview',
    altleft: 'Move task selection left',
    altright: 'Move task selection right',
    altup: 'Move task selection up',
    annotations: 'Annotations',
    ctrldown: 'Move metadata cell selection down',
    ctrlleft: 'Move metadata cell selection left',
    ctrlright: 'Move metadata cell selection right',
    ctrlup: 'Move metadata cell selection up',
    draw: 'Set draw mode on',
    plhome: 'Go to the first frame',
    plend: 'Go to the last frame',
    plaltright: 'Go to the first frame of the next shot',
    plaltleft: 'Go to the last frame of the next shot',
    navigation: 'Navigation',
    redo: 'Redo',
    undo: 'Undo',
    playlist_navigation: 'Playlist navigation',
    remove_annotation: 'Remove annotation',
    shortcuts: 'Shortcuts'
  },

  intro: {
    main: 'To make our project thrive, we need your support. To help us, we propose you simple contributions:',
    first: 'Star our repository on ',
    second: 'Follow us on ',
    third: 'Join our',
    four: 'Suggest new features on ',
    five: 'Donate to our ',
    six: 'Have a look at our cloud and on-premise ',
    seven: 'Share Kitsu with your friends and colleagues',
    eight: 'Thank you for your help!',
    title: 'How to support Kitsu'
  },

  login: {
    back_to_login: 'Go back to login page',
    choose_totp: 'Enter two-factor authentication code via TOTP',
    choose_fido: 'Use a FIDO device',
    choose_email_otp: 'Enter two-factor authentication code via email',
    choose_recovery_code: 'Use a recovery code',
    error_requesting_fido: 'An error occurred while requesting FIDO challenge.',
    error_sending_email: 'An error occurred while sending OTP via email.',
    first_connection: 'For your first connection, we need you to set up a password. For that, you have to run the reset password procedure.',
    first_connection_2: 'Please, give us the email used for your Kitsu account and we will send you the reset password link.',
    first_connection_title: 'Welcome to Kitsu!',
    forgot_password: 'Forgot password?',
    information_totp: 'Open the two-factor authenticator (TOTP) app on your mobile device to view your authentication code.',
    information_fido: 'Plug your FIDO device into your computer.',
    information_email_otp: 'Check your emails to view your authentication code.',
    information_recovery_code: 'If you are unable to use any other two-factor authentication, enter one of your recovery codes to verify your identity.',
    login: 'Log in',
    login_failed: 'Login failed, please verify your credentials.',
    login_server_failed: 'A server error occurred while logging in.',
    login_page: 'Cancel',
    redirecting: 'Redirecting in {secondsLeft} seconds...',
    reset_change_password: 'Change password',
    reset_change_password_failed: 'Changing password failed. Please, restart the whole procedure again.',
    reset_change_password_form_failed: 'There is a problem with the password you gave. Please, verify that it is at least 8 chars long and that both passwords match.',
    reset_change_password_succeed: 'Your password was changed successfully. Please, go back to the login page to use it.',
    reset_change_password_title: 'Enter a new password',
    reset_password: 'Reset Password',
    reset_password_failed: 'Resetting your password failed. Please verify your email.',
    reset_password_succeed: 'Resetting your password succeeded. Please check your inbox.',
    reset_password_title: 'Enter your email to reset your password',
    retry_fido_challenge: 'Retry FIDO challenge',
    send_email_otp: 'Resend OTP by email',
    title: 'Log in to Kitsu',
    too_many_failed_login_attemps: 'Too many failed login attempts, please wait one minute.',
    unable_to_verify_email_otp: 'Unable to verify with email?',
    unable_to_verify_recovery_code: 'Don\'t have a recovery code?',
    unable_to_verify_totp: 'Unable to verify with TOTP?',
    unable_to_verify_fido: 'Don\'t have your FIDO device?',
    verify: 'Verify',
    wrong_totp: 'TOTP verification failed.',
    wrong_email_otp: 'OTP via email verification failed.',
    wrong_recovery_code: 'Recovery code verification failed.',
    wrong_fido_challenge: 'FIDO challenge verification failed.',
    fields: {
      email: 'Email',
      password: 'Password',
      password2: 'Password again',
      totp: 'TOTP',
      email_otp: 'OTP via email',
      recovery_code: 'Recovery code'
    }
  },

  logs: {
    current_date_label: 'Show logs for',
    empty_list: 'There are no logs for the selected date.',
    events: 'events listed for the current day',
    title: 'Logs',
    preview_files: {
      date: 'Creation date',
      empty_list: 'There are no broken or ongoing previews.',
      entity_name: 'Entity name',
      explaination: 'This page lists all the preview files in an intermediary state: processing or broken. It lets you know how many preview files are processing or which files failed to be normalized and stored on Kitsu.',
      file_name: 'File name',
      mark_broken: 'Mark as broken',
      person: 'User',
      production: 'Production',
      revision: 'Revision',
      status: 'Status',
      task_type_id: 'Task type',
      title: 'Preview files'
    }
  },

  main: {
    about: 'About',
    active: 'Active',
    activity: 'Activity',
    add: 'add',
    after: 'After',
    admin: 'Admin',
    all: 'All',
    all_assets: 'All assets',
    all_edits: 'All edits',
    all_shots: 'All shots',
    archived: 'Archived',
    attach_snapshots: 'Attach snapshots from your annotation',
    avatar: {
      open_page: 'Open {personName} page',
      unassign: 'Unassign {personName}'
    },
    before: 'Before',
    cancel: 'Cancel',
    clear_selection: 'Clear current selection',
    close: 'Close',
    color: 'Color',
    copy: 'Copy',
    column_visibility: 'Visible columns',
    confirmation: 'Confirm',
    confirmation_and_stay: 'Confirm and stay',
    date: 'Date',
    dark_theme: 'Dark Theme',
    days: 'days',
    days_spent: 'day spent | days spent',
    days_estimated: 'day estimated | days estimated',
    delete: 'Delete',
    delete_all: 'Delete all',
    delete_text: 'Are you sure you want to remove {name} from your database?',
    department: 'Department',
    description: 'Description',
    documentation: 'Documentation',
    edit: 'Edit',
    empty_comment: 'Empty comment',
    empty_schedule: 'There are no tasks or they don\'t have start dates.',
    end_date: 'End date',
    estimation: 'Estimation',
    estimation_short: 'Est.',
    files_selected: 'files selected',
    filter_group: 'Filter Group',
    filter_group_add: 'Add A Filter Group',
    filter_group_edit: 'Edit A Filter Group',
    filter_group_empty: 'No filter',
    filter_group_error: 'An error occurred while saving this filter group.',
    for: 'For',
    fps: 'FPS',
    frames: 'Frames',
    frame_in: 'Frame In',
    frame_out: 'Frame Out',
    from: 'From',
    go_productions: 'Return To Production',
    hide_support_chat: 'Hide support chat',
    history: 'history',
    info: 'Information',
    import: 'Import',
    is_shared: 'Shared with the whole team',
    home: 'return to home page',
    or: 'or',
    no: 'No',
    no_results: 'No results',
    less_filters: 'Fewer filters',
    link: 'Link',
    load_more: 'Load more',
    loading: 'Loading...',
    loading_data: 'Loading data',
    loading_error: 'An error occurred while loading data.',
    logout: 'Logout',
    modify: 'Modify',
    minimize: 'Minimize',
    main_pack: 'Main Pack',
    maximize: 'Maximize',
    man_days: 'person day | person days',
    more_filters: 'More filters',
    move_action_bar: 'Move action bar',
    nb_frames: 'frame | frames',
    on: 'On',
    optional: 'optional',
    person: 'Person',
    profile: 'Profile',
    production: 'Production',
    remove: 'Remove',
    reload: 'Reload',
    remaining: 'Remaining',
    reply: 'Reply',
    save: 'Save',
    search_query: 'Search query',
    search_query_edit: 'Edit search filter',
    search_query_edit_error: 'An error occurred while updating this search filter.',
    select_column: 'Select column',
    select_file: 'Select files from your hard drive',
    show: 'Show',
    show_support_chat: 'Show support chat',
    sorted_by: 'Sorted by',
    sort_by: 'Sort by',
    start_date: 'Start date',
    stick: 'Stick',
    studio: 'Studio',
    status: 'Status',
    tasks: 'Tasks',
    to: 'To',
    timeSpent: 'Time Spent',
    tutorials: 'Tutorials',
    type: 'Type',
    unstick: 'Unstick',
    user: 'User',
    week: 'Week',
    white_theme: 'White Theme',
    workspace: 'Workspace',
    yes: 'Yes',
    search: {
      type: 'Type 3 characters at least to perform the search',
      no_result: 'There are no results for this search',
      no_filter: 'Select at least one filter to perform the search'
    },
    csv: {
      choose: 'Choose',
      error_upload: 'An error occurred while uploading your CSV.',
      export_current_view: 'Export current view',
      export_file: 'Export',
      import_file: 'Import',
      import_title: 'Import data from CSV',
      legend: 'Legend',
      legend_ok: 'Column that will be imported',
      legend_line_ok: 'Line that will be created',
      legend_ignored: 'Column that was not matched and will not be imported',
      legend_missing: 'Expected column that was not found',
      legend_missing_optional: 'Optional column that was not found',
      legend_disabled: 'Line that will not be updated or created',
      legend_overwrite: 'Line that will be updated',
      paste: 'Paste',
      paste_code: 'Please paste here your CSV data:',
      preview: 'Preview',
      preview_episode_name: 'Episode name',
      preview_title: 'Preview of your imported data',
      preview_description: 'Upload a .csv file to populate your entity table.',
      preview_required: 'Headers are used to match columns with the data to set. They must be included as the first row.',
      preview_reupload: 'Reupload .CSV file',
      required_fields: 'Your CSV requires the following columns:',
      optional_fields: 'Descriptive columns (optional):',
      generic_fields: 'Metadata and task columns (optional):',
      select_file: 'Please select a file from one of your folder:',
      tab_select_file: 'Upload a CSV file',
      tab_paste_code: 'Paste a CSV data',
      unknown: 'Unknown column',
      upload_file: 'Browse local files',
      options: {
        title: 'Options',
        update: 'Update existing data'
      }
    },
    edl: {
      import_file: 'Import EDL',
      import_title: 'Import frames / in / out from edit decision list',
      explanation: 'It\'s possible to import every OpenTimeLineIO supported files (otio, fcp_xml, fcpx_xml, edl). Shots will be created or updated with the given frames, frame in and frame out. Frame in start at 0.',
      select_file: 'Please select a file from one of your folder:',
      upload_file: 'Browse local files',
      error_upload: 'An error occurred while uploading your EDL.',
      upload_edl: 'Upload EDL',
      naming_convention: 'Naming convention',
      match_case: 'Match case'
    }
  },

  menu: {
    assign_tasks: 'Assign tasks',
    change_priority: 'Change priority',
    change_status: 'Change status',
    create_tasks: 'Create tasks',
    delete_assets: 'Delete assets',
    delete_concepts: 'Delete concepts',
    delete_edits: 'Delete edits',
    delete_shots: 'Delete shots',
    delete_tasks: 'Delete tasks',
    edit_concepts: 'Edit concept links',
    generate_playlist: 'Generate a playlist',
    run_custom_action: 'Run custom action',
    set_estimations: 'Set estimations',
    set_thumbnails: 'Set thumbnails from last preview',
    subscribe: 'Subscribe to notifications'
  },

  my_checks : {
    title: 'Task to check | Tasks to check'
  },

  news: {
    all: 'All',
    commented_on: 'commented on',
    infos: 'Infos',
    only_comments: 'Only comments',
    only_previews: 'Only previews',
    hide_stats: 'Hide stats',
    news: 'news',
    no_news: 'There is no news.',
    set_preview_on: 'set preview on',
    show_stats: 'Show stats',
    task_status: 'Task status',
    task_type: 'Task type',
    title: 'News Feed'
  },

  not_found: {
    text: 'There was something wrong with the link you clicked on, the target was not found. We encourage you to come back to the home page.',
    title: 'Page not found...'
  },

  notifications: {
    and_change_status: 'and changed status to',
    all_types: 'All types',
    assigned_you: 'assigned you to',
    commented_on: 'commented on',
    mention_you_on: 'mentioned you on',
    new_revision: 'New revision published',
    no_notifications: 'There is currently no notification for you for your current projects.',
    only_assignations: 'Only assignments',
    only_comments: 'Only comments',
    only_mentions: 'Only mentions',
    only_replies: 'Only replies',
    replied_on: 'replied to a comment on',
    unread_notifications: 'unread notification | unread notifications',
    title: 'Notifications',
    with_preview: 'with a preview'
  },

  people: {
    active: 'Active',
    active_persons: 'active user | active users',
    add_member_to_team: 'Add a member to the team',
    confirm_edit: 'Save user changes',
    create: 'Create user',
    create_invite: 'Create user and send invitation',
    create_error: 'A server error occurred while creating or updating this user. Please contact our support team for more information.',
    delete_error: 'An error occurred while deleting this user. There are probably data linked to it. Are you sure this user has no assignments or wrote no comment?',
    delete_text: 'Are you sure you want to remove {personName} from your database? Every related comment and preview will be deleted. Please confirm by typing the full user name below.',
    departments_empty: 'This user isn\'t linked to any department.',
    edit_avatar_error: 'An error occurred while updating the user avatar.',
    edit_title: 'Edit user',
    empty_team: 'There is no one listed in the project team.',
    import_from_production: 'Import a team from another production',
    import_from_department: 'Import an entire department',
    import_from_unlisted: 'Add from unlisted people',
    invite: 'Send an invitation',
    invite_error: 'An error occurred while sending the invitation',
    invite_success: 'Invitation was successfully sent',
    new_person: 'Add a new user',
    no_task_assigned: 'There are no running tasks assigned to you',
    persons: 'user | users',
    running_tasks: 'Running tasks',
    select_person: 'Select a user...',
    team: 'Team',
    title: 'People',
    unactive: 'Inactive',
    user_limit_error: 'You have reached your user limit. Please contact our team to upgrade your plan.',
    change_password_for: 'Change password for',
    change_password_error: 'An error occurred while changing password. Please verify the new password.',
    disable_2FA: 'Disable 2FA',
    disable_2FA_error: 'An error occurred while disabling two-factor authentication.',
    fields: {
      active: 'Active',
      contract: 'Contract',
      departments: 'Departments',
      email: 'Email',
      first_name: 'First name',
      last_name: 'Last name',
      old_password: 'Current password',
      password: 'New password',
      password_2: 'New password (repeat)',
      phone: 'Phone',
      role: 'Role'
    },
    list: {
      active: 'Active',
      contract: 'Contract',
      departments: 'Departments',
      email: 'Email',
      expiration: 'Expiration',
      name: 'Name',
      phone: 'Phone',
      role: 'Role'
    },
    role: {
      all: 'All',
      admin: 'Studio Manager',
      client: 'Client',
      manager: 'Production Manager',
      supervisor: 'Supervisor',
      user: 'Artist',
      undefined: '',
      vendor: 'Vendor'
    },
    contract: {
      'open-ended': 'Open-ended',
      'fixed-term': 'Fixed-term',
      'short-term': 'Short-term',
      freelance: 'Freelance',
      apprentice: 'Apprentice',
      internship: 'Internship'
    }
  },

  preview_room: {
    join_room: 'Join Review Room',
    leave_room: 'Leave Room'
  },

  playlists: {
    add_assets: 'Add assets',
    add_selection: 'Add selection',
    add_sequences: 'Add sequences',
    add_shots: 'Add shots',
    add_sequence: 'Add the whole sequence',
    add_episode: 'Add the whole episode',
    add_movie: 'Add the whole movie',
    apply_task_type_change: 'This will set the last revision for the given task type on all entities.',
    available_build: 'Available builds',
    build_daily: 'Daily pending',
    build_weekly: 'All Pending',
    build_mp4: 'Build .mp4 (beta)',
    building: 'Building...',
    client_playlist: 'Client Playlist',
    comparing_missing_plan: 'Shot missing for current type',
    create_for_selection: 'Generate a playlist from selected tasks',
    create_title: 'Create playlist',
    created_at: 'Created at:',
    delete_text: 'Are you sure you want to remove {name} from your database?',
    delete_error: 'An error occurred while deleting this playlist.',
    edit_error: 'An error occurred while saving this playlist.',
    download_csv: 'Download .csv',
    download_zip: 'Download .zip',
    failed: 'Failed',
    filter_task_type: 'Filtered with task type',
    for_client: 'The Client',
    for_studio: 'The Studio',
    edit_title: 'Edit playlist',
    last_creation: 'Last creations',
    last_modification: 'Last modifications',
    loading_error: 'A server error occurred. Playlists cannot be loaded.',
    new_playlist: 'Add a playlist',
    no_build: 'No build',
    no_playlist: 'There is currently no playlist for this project or episode.',
    no_preview: 'No preview',
    no_selection: 'Please select a playlist on the left.',
    no_sequence_for_episode: 'There is no sequence for this episode',
    no_shot_for_production: 'There is no shot for this production',
    select_shot: 'Please select a shot in the right column',
    select_playlist: 'Please select a playlist in the left column',
    select_task_type: 'Change task type for all entities',
    title: 'Playlists',
    updated_at: 'Updated at:',
    remove: 'remove',
    fields: {
      name: 'Name',
      created_at: 'Creation date',
      updated_at: 'Update date',
      for_entity: 'Select entity to display',
      for_client: 'To be shared with'
    },
    actions: {
      annotation: 'Annotation',
      annotation_draw: 'Draw annotations',
      annotation_text: 'Double click on the preview to add some text',
      annotation_delete: 'Delete annotation',
      annotation_erase: 'Erase annotation',
      annotation_redo: 'Redo annotation',
      annotation_undo: 'Undo annotation',
      annotation_big: 'Big',
      annotation_medium: 'Medium',
      annotation_small: 'Small',
      annotation_zoom_pan: 'Enable zoom and pan',
      change_task_type: 'Change task type',
      current_time: 'Position in the video',
      comments: 'Show/Hide comments',
      delete: 'Delete playlist',
      display_revision: 'Display revision',
      default: 'default',
      download: 'Download playlist',
      download_file: 'Download file',
      edit: 'Edit playlist',
      entity_list: 'Show/Hide entity list',
      entity_index: 'Index of the current entity in the playlist',
      entities_number: 'Number of entities in the playlist',
      exit_play_full: 'Exit full movie playing',
      frame_number: 'Frame number',
      files_add: 'Add a file to the revision',
      files_delete: 'Delete and remove the file from the revision',
      files_next: 'Next file for this revision',
      files_position: 'File index for this revision',
      files_previous: 'Previous file for this revision',
      frames_per_picture: 'Frames per picture',
      fullscreen: 'See file in full screen',
      looping: 'Loop on current video',
      mute: 'Mute',
      max_duration: 'Video duration',
      next_frame: 'Next frame',
      next_shot: 'Next entity',
      number_of_files: 'Number of files for this revision',
      object_background: 'Set a new environment map to the 3D model',
      open_link: 'Open link',
      overlay: 'Overlay',
      pause: 'Pause',
      play: 'Play',
      previous_frame: 'Previous frame',
      previous_shot: 'Previous entity',
      save_playlist: 'Save playlist',
      see_original_file: 'Open original file in a new tab',
      select_background: 'Select a background',
      side_by_side: 'Side by side',
      speed: 'Change speed',
      split_screen: 'Compare with other task types',
      switch_ld: 'Switch to low definition',
      switch_hd: 'Switch to high definition',
      toggle_annotations: 'Show/Hide annotations when the movie is paused',
      toggle_laser: 'Enable/Disable drawing laser mode',
      toggle_environment_skybox: 'Show/Hide skybox background',
      toggle_playing_annotations: 'Show/Hide annotations while playing movies',
      toggle_waveform: 'Show/Hide movie waveform',
      toggle_wireframe: 'Enable/Disable wireframe rendering',
      unmute: 'Unmute'
    }
  },

  productions: {
    current: 'Selected production',
    delete_text: 'Are you sure you want to remove {name} from your database? Please, confirm by typing the name of the project you want to delete in the text field.',
    delete_error: 'An error occurred while deleting this production. There are probably data linked to it. Are you sure this production has no task, shot, or asset linked to it? Kitsu doesn\'t allow production deletion. If you don\'t want to see the production anymore, you can close it instead.',
    edit_error: 'An error occurred while editing production. Please contact our support team.',
    edit_title: 'Edit',
    load_stats: 'Load stats',
    new_production: 'Add a production',
    number: 'production | productions',
    open_productions: 'My Productions',
    picture: 'Change picture',
    title: 'Productions',

    creation: {
      add_assets: 'Add assets (optional)',
      add_asset_types: 'Add asset types',
      add_asset_types_description: 'Add asset types to your project',
      add_assets_button: 'Add assets',
      add_assets_description: 'Add or import the assets for your production.',
      add_shots: 'Add shots (optional)',
      add_shots_button: 'Add shots',
      add_shots_description: 'Add or import the shots for your production.',
      assets_to_import: 'assets to import',
      create_production: 'Create production',
      create_button: 'All done let\'s go 🚀',
      create_button_disabled: 'Please fill all the form',
      error: 'An error occurred while creating the production',
      errorImportingAssets: 'An error occurred while importing your assets. The production has been created though!',
      errorImportingShots: 'An error occurred while importing your shots. The production has been created though!',
      explaination_date: 'These dates are needed to set up your schedule.',
      explaination_type: 'If you choose TV Show, the production will be splitted in episodes.',
      explaination_video: 'These settings are used to normalize the uploaded videos.',
      give_a_name: 'Give your production a name',
      give_a_name_description: 'Start by giving your production a meaningful name.',
      import_assets_button: 'Import assets',
      import_shots_button: 'Import shots',
      new_project: 'New Project',
      placeholder_date_start: 'starts at',
      placeholder_date_end: 'ends at',
      placeholder_name: 'My Awesome Production',
      placeholder_fps: '25',
      placeholder_ratio1: '16',
      placeholder_ratio2: '9',
      placeholder_resolution1: '1920',
      placeholder_resolution2: '1080',
      production_settings: 'Set your production parameters',
      production_settings_description: 'Define your production type, the format of your video previews and schedule your production.',
      select_asset_task_type: 'Select asset task type',
      select_asset_task_type_description: 'These task types define the building steps of your assets.',
      select_shot_task_type: 'Select shot task type',
      select_shot_task_type_description: 'These task types define the building steps of your shots.',
      select_edit_task_type: 'Select edit task type',
      select_edit_task_type_description: 'These task types define the building steps of your edits.',
      select_task_status: 'Select task status',
      select_task_status_description: 'Select the task statuses you will use during the production.',
      start_and_end_dates: 'Start and end dates',
      shots_to_import: 'shots to import'
    },
    home: {
      create_new: 'Create a new production',
      empty: 'You don\'t have open productions. What about creating a new one?',
      no_task: 'You have no task assigned. See your supervisor to see what you can do!',
      no_prod_for_client: 'You don\'t have access to any production. Contact your contractor to obtain an access.',
      title: 'My Productions',
      welcome: 'Welcome to Kitsu'
    },

    fields: {
      end_date: 'End date',
      episode_span: 'Episode spacing',
      fps: 'FPS',
      is_clients_isolated: 'Isolate client comments (not visible to each others)',
      is_preview_download_allowed: 'Allow artists to download previews',
      is_set_preview_automated: 'Set new preview as entity thumbnail automatically',
      max_retakes: 'Maximum number of retakes',
      name: 'Name',
      nb_episodes: 'Number of episodes',
      homepage: 'Homepage (first page displayed)',
      ratio: 'Ratio',
      resolution: 'Resolution',
      start_date: 'Start date',
      status: 'Status',
      style: 'Style',
      type: 'Type'
    },

    metadata: {
      add_explaination: 'Add specific data required by this project.',
      add_failed: 'An error occurred while adding metadata to your project.',
      add_new_values: 'There are currently no available values.',
      available_values: 'Available values',
      boolean: 'Checkbox',
      checklist: 'Checklist',
      choices: 'List of values',
      delete_text: 'Are you sure you want to delete this column and related data for all assets of this production?',
      delete_error: 'An error occurred while deleting this metadata column.',
      edit_title: 'Edit metadata column',
      error: 'An error occurred while adding the metadata column. Make sure there is no column with a similar name and that all fields are filled. If the problem persists, please contact the support team.',
      number: 'Number',
      string: 'Text',
      tags: 'List of tags',
      title: 'Add metadata column'
    },

    brief: {
      empty: 'There is no brief yet. What about creating one?',
      title: 'Brief'
    },

    homepage: {
      'assets': 'Assets',
      'shots': 'Shots',
      'sequences': 'Sequences'
    },

    parameters: {
      title: 'Parameters',
      save: {
        button: 'Confirm'
      }
    },

    status: {
      closed: 'Closed',
      open: 'Open',
      active: 'Open',
      archived: 'Closed'
    },

    style: {
      '2d': '2D Animation',
      '3d': '3D Animation',
      '2d3d': '2D/3D Animation',
      ar: 'Augmented Reality',
      archviz: 'Archviz',
      commercial: 'Commercial',
      catalog: 'Catalog',
      immersive: 'Immersive Experience',
      motion_design: 'Motion Design',
      nft: 'NFT collection',
      stop_motion: 'Stop Motion',
      vfx: 'VFX',
      video_game: 'Video Game',
      vr: 'Virtual Reality'
    },

    type: {
      short: 'Short',
      featurefilm: 'Feature Film',
      tvshow: 'TV Show',
      assets: 'Only assets',
      shots: 'Only shots'
    }
  },

  profile: {
    change_avatar: 'Change avatar',
    clear_avatar: 'Remove avatar',
    info_title: 'Information',
    language: 'Language',
    notifications_enabled: 'Email notifications enabled',
    notifications_slack_enabled: 'Slack notifications enabled',
    notifications_slack_user: 'Slack member ID',
    notifications_mattermost_enabled: 'Mattermost notifications enabled',
    notifications_mattermost_user: 'Mattermost username',
    notifications_discord_enabled: 'Discord notifications enabled',
    notifications_discord_user: 'Discord username',
    password_title: 'Change password',
    timezone: 'Timezone',
    title: 'Your Profile',
    webhook_error: 'the webhook mattermost does not correspond to a hook',
    avatar: {
      title: 'Change avatar',
      error_upload: 'There was an error while uploading picture.',
      updating: 'Updating...',
      removing: 'Removing...'
    },
    change_password: {
      button: 'Change password',
      error: 'An error occurred while changing password. Please verify your current password.',
      success: 'Your password was successfully changed!',
      unvalid: 'Your new password confirmation doesn\'t match or your password is too short (8 chars, at least, is expected).'
    },
    two_factor_authentication: {
      title: 'Two-factor authentication',
      scan_qrcode: 'Please scan this QR code in your TOTP application.',
      otp_secret: 'OTP secret',
      totp: {
        button_enable: 'Enable TOTP',
        button_disable: 'Disable TOTP',
        button_validate_disable: 'Validate and disable TOTP',
        button_validate: 'Validate and enable TOTP',
        error_enable: 'An error occurred while enabling TOTP.',
        error_disable: 'An error occurred while disabling TOTP.'
      },
      email_otp: {
        button_enable: 'Enable OTP via email',
        button_disable: 'Disable OTP via email',
        button_validate_disable: 'Validate and disable OTP via email',
        button_validate: 'Validate and enable OTP via email',
        error_enable: 'An error occurred while enabling OTP via email.',
        error_disable: 'An error occurred while disabling OTP via email.'
      },
      fido: {
        device_name: 'FIDO device name',
        registered_devices_title: 'Registered FIDO devices:',
        button_register: 'Register new FIDO device',
        error_register: 'An error occurred while registering FIDO device.',
        error_unregister: 'An error occurred while unregistering FIDO device.',
        button_unregister: 'Validate and unregister FIDO device'
      },
      recovery_codes: {
        title: 'Recovery codes',
        button_new: 'Get new recovery codes',
        button_validate: 'Validate and get new recovery codes',
        warning: 'Keep your recovery codes in a safe spot. These codes are the last resort for accessing your account in case you lose your password and second factors. If you cannot find these codes, you will lose access to your account.',
        error_new: 'An error occurred while generating new recovery codes'
      }
    },
    save: {
      button: 'Save changes',
      error: 'An error occurred while saving changes'
    }
  },

  settings: {
    available_items: 'Available items',
    change_logo: 'Change logo',
    integrations: 'Integrations',
    import_from_production: 'Import from another production',
    logo: 'Studio logo',
    no_logo: 'There is no logo set.',
    no_more_available_items: 'There are no more items available to add.',
    remove_logo: 'Remove studio logo',
    set_logo: 'Set studio logo',
    title: 'Settings',
    webhook_error: 'An error occurred while posting webhook',
    fields: {
      name: 'Studio name',
      hours_by_day: 'Hours by day',
      slack_token: 'Slack Token (Optional)',
      mattermost_webhook: 'Mattermost Webhook (Optional)',
      discord_token: 'Discord Token (Optional)',
      timesheets_locked: 'Lock artist timesheets older than 1 week',
      use_original_name: 'Use original file name for downloads',
      show_hd_default: 'Show movies with HD quality by default (slower)'
    },
    production: {
      empty_list: 'The list is currently empty. It means that all data from the main settings are available to users. Add some entries to limit choices for this production.',
      empty_automation_list: 'There is no automation set for this production.',
    },
    save: {
      button: 'Save settings',
      error: 'A server error occurred while saving settings'
    }
  },

  task_status: {
    create_error: 'An error occurred while saving this task status. Are you sure there is no task status with a similar name?',
    delete_text: 'Are you sure you want to remove {name} from your database?',
    delete_error: 'An error occurred while deleting this task status. There are probably data linked to it. Are you sure this task status has no task linked to it?',
    edit_title: 'Edit task status',
    name: 'task status',
    number: 'task status | task status',
    new_task_status: 'Add a task status',
    title: 'Task Status',
    title_entities: 'Status for entities',
    title_concepts: 'Status for concepts',
    help: {
      entities: 'Assets, shots, sequences, ...'
    },
    fields: {
      color: 'Color',
      for_concept: 'For concept',
      is_artist_allowed: 'Is artist allowed',
      is_client_allowed: 'Is client allowed',
      is_done: 'Is done',
      is_feedback_request: 'Is feedback request',
      is_retake: 'Has retake value',
      is_default: 'Is default',
      name: 'Name',
      short_name: 'Short name'
    }
  },

  task_types: {
    delete_text: 'Are you sure you want to remove {name} from your database?',
    delete_error: 'An error occurred while deleting this task type. There are probably data linked to it. Are you sure this task type has no task linked to it?',
    edit_title: 'Edit task type',
    create_error: 'An error occurred while creating the task type. Please, check that there is no task type with a similar name.',
    new_task_type: 'Add a task type',
    no_task_types: 'There is no task type for this entity type',
    number: 'task type | task types',
    title: 'Task Types',
    fields: {
      dedicated_to: 'For',
      department: 'Department',
      color: 'Color',
      name: 'Name',
      allow_timelog: 'Timelog',
      priority: 'Priority'
    }
  },

  sequences: {
    all_sequences: 'All sequences',
    edit_error: 'An error occurred while saving this sequence. Are you sure there is no sequence with a similar name?',
    delete_text: 'Are you sure you want to remove {name} from your database? All related shots and previews will be deleted. Please confirm by typing the sequence name below.',
    delete_error: 'An error occurred while deleting this sequence. There are probably data linked to it. Are you sure this sequence has no shot linked to it?',
    edit_title: 'Edit sequence',
    empty_list: 'There is no sequence in the production. What about creating some?',
    empty_list_client: 'There is no sequence in this production.',
    new_sequence: 'New sequence',
    no_casting: 'The sequence casting is empty.',
    number: 'sequence | sequences',
    stats_title: 'Sequence Stats',
    title: 'Sequences',
    tasks: 'Tasks',
    fields: {
      name: 'Name',
      description: 'description',
      person: 'Person',
      placeholder: 'SQ01'
    }
  },

  edits: {
    all_edits: 'All edits',
    cancel_text: 'Are you sure you want to archive {name}?',
    creation_explaination: 'Upload an edit file to create a new edit',
    delete_text: 'Are you sure you want to remove {name} from your database?',
    delete_error: 'An error occurred while deleting this edit. There are probably data linked to it.',
    delete_for_selection: 'Delete the selected edit | Delete the {nbSelectedEdits} selected edits',
    edit_error: 'An error occurred while saving this edit. Are you sure there is no edit with a similar name?',
    edit_title: 'Change edit',
    empty_list: 'There are no edits in the production. What about creating some?',
    empty_list_client: 'There are no edits in this production.',
    new_edit: 'New edit',
    history: 'Edit values history',
    number: 'edit | edits',
    restore_error: 'An error occurred while restoring this edit.',
    tasks: 'Tasks',
    title: 'Edits',
    fields: {
      name: 'Name',
      episode: 'Ep.',
      description: 'Description',
      person: 'Modifier'
    }
  },

  schedule: {
    title: 'Schedule',
    title_main: 'Main Schedule',
    overall_man_days: 'Person-days',
    md: 'md',
    today: 'Today',
    zoom_level: 'Zoom level',
    milestone: {
      add_milestone: 'Add milestone for',
      delete_milestone: 'Delete milestone',
      edit_milestone: 'Edit milestone for',
      name: 'Name',
      error: 'An error occurred while adding or editing the milestone. Please try again.'
    }
  },

  team_schedule: {
    title: 'Schedule',
    title_main: 'Team Schedule',
    person_placeholder: 'Select a person...'
  },

  quota: {
    average: 'Average',
    count: 'Count',
    count_label: 'Count unit',
    compute_mode: 'Compute mode',
    day: 'Day',
    detail_label: 'Detail level',
    details_name: 'Name',
    details_seconds: 'Seconds',
    details_frames: 'Frames',
    feedback_date: 'Feedback date',
    explaination: 'Shots are considered ended on the first feedback request. Then, quotas are weighted following time spent on the task (when the artist filled his timesheet).\n If no time is filled, it considers that:\n * The task was started at the first status change to WIP \n* The task was done the day the feedback request was made.\n * It splits the done frames among all business days between the start and the end.',
    explaination_feedback: 'The shot is done on the first feedback request. Its number of frames is added to the quotas for this day.',
    export_quotas: 'Export quotas',
    frames: 'Frames',
    highlight_quotas: 'Highlight quotas below:',
    month: 'Month',
    month_label: 'Month',
    no_quota: 'There is no quota for this task type.',
    name: 'Name',
    quota_day: 'Quotas per day',
    quota_week: 'Quotas per week',
    quota_month: 'Quotas per month',
    seconds: 'Seconds',
    year_label: 'Year',
    title: 'Quotas',
    type_label: 'Type',
    weight: 'Weight',
    weighted: 'Weighted',
    week: 'Week'
  },

  shots: {
    casting: 'Shot casting',
    cancel_text: 'Are you sure you want to archive {name}?',
    creation_explaination: 'To add shots you need first to create an episode and a sequence. Type an episode name in the bottom of the left column then click on add to create a new episode. Select this episode and repeat the same operation for sequence. Finally, select a sequence and type a shot name in the field at the bottom of the right column. Click on the add button below. Your first shot was created. You can now add many more! If it\'s not a TV Show, you have to directly create a sequence.',
    delete_for_selection: 'Delete the selected shot | Delete the {nbSelectedShots} selected shots',
    delete_text: 'Are you sure you want to remove {name} from your database?',
    delete_error: 'An error occurred while deleting this shot. There are probably data linked to it. Are you sure this shot has no task linked to it?',
    edit_success: 'Shot {name} successfully edited.',
    edit_fail: 'Creation or edition failed, an error occurred. Make sure that you are not renaming the shot with a name already listed for a given sequence.',
    edit_title: 'Edit shot',
    empty_list: 'There is no shot in the production. What about creating some?',
    empty_list_client: 'There is no shot in this production.',
    episodes: 'Episodes',
    history: 'Shot values history',
    multiple_delete_error: 'An error occurred while deleting a shot. There is probably some data linked to a shot. Are you sure there is no task linked to a selected shot?',
    new_shot: 'Add a shot',
    new_shots: 'Create shots',
    new_sequences: 'Add sequences',
    new_episodes: 'Add episodes',
    no_casting: 'The shot casting is empty.',
    number: 'shot | shots',
    manage: 'Create shots',
    new_success: 'Shot {name} successfully created.',
    padding: 'Shot Padding',
    restore_text: 'Are you sure you want to restore {name} from your archive?',
    restore_error: 'An error occurred while restoring this shot.',
    sequences: 'Sequences',
    tasks: 'Shot Tasks',
    title: 'Shots',
    fields: {
      description: 'Description',
      nb_frames: 'Frames',
      episode: 'Episode',
      frame_in: 'In',
      frame_out: 'Out',
      fps: 'FPS',
      max_retakes: 'Max Rtks',
      name: 'Name',
      person: 'Modifier',
      placeholder: 'SH01',
      production: 'Prod',
      resolution: 'Resolution',
      sequence: 'Sequence',
      time_spent: 'Time'
    }
  },

  server_down: {
    text: 'Please contact our support, your system administrator or your IT department to understand what is going wrong.',
    title: 'Kitsu encountered an error while reaching its data API'
  },

  statistics: {
    all: 'All',
    count: 'Counts',
    count_mode: 'Count',
    data_mode: 'Data',
    display_mode: 'Display',
    episode_status: 'Episode status',
    frames: 'Frames',
    only_running: 'Only running',
    pie: 'Pie charts',
    retakes: 'Retakes',
    status: 'Status',
    shots: 'Shots'
  },

  tasks: {
    all: 'All tasks',
    add_preview: 'Add files for a new preview revision',
    add_preview_error: 'An error occurred while adding preview.',
    add_revision_confirm: 'Add files to publish',
    all_tasks: 'All tasks',
    auto_revision: 'Restore automatic revision number',
    assign: 'Assign to the selected task | Assign to the {nbSelectedTasks} selected tasks ',
    assign_explaination: 'Select a person to assign...',
    assignation_warning: 'Warning: you won\'t see the result because you are hiding assignments',
    back_to_list: 'back to list',
    bigger: 'Widen task panel',
    big_thumbnails: 'Show big thumbnails',
    build_playlist: 'Build playlist from list',
    calendar: 'Calendar',
    confirm_attachments: 'Add files as attachments',
    change_status_to: 'Change task status to:',
    change_status: 'Post comment',
    change_preview: 'Change preview',
    change_priority: 'Change priority of the selected task | Change priority of the {nbSelectedTasks} selected tasks ',
    change_priority_to: 'New priority:',
    change_task_status: 'Change status for the selected task | Change status for the {nbSelectedTasks} selected tasks',
    clear_all_assignations: 'unassign all',
    clear_assignations: 'unassign from selection',
    clear_own_assignations: 'clear your assignments',
    comment_image: 'Attach files',
    create_for_selection: 'Create a task for each empty cell',
    create_tasks: 'Add tasks',
    create_tasks_shot: 'Add tasks for current shots',
    create_tasks_shot_explaination: 'You are going to create a new task for each shot of the current project for the given task type. Do you want to continue?',
    create_tasks_shot_failed: 'A server error occurred while proceeding creations.',
    create_tasks_asset: 'Add tasks for current assets',
    create_tasks_asset_explaination: 'You are going to create a new task for each asset of the current project for the given task type. Do you want to continue?',
    create_tasks_asset_failed: 'A server error occurred while proceeding creations.',
    create_tasks_edit: 'Add tasks for current edits',
    create_tasks_edit_explaination: 'You are going to create a new task for each edit of the current project for the given task type. Do you want to continue?',
    create_tasks_edit_failed: 'A server error occurred while proceeding creations.',
    create_tasks_episode: 'Add tasks for current episodes',
    create_tasks_episode_explaination: 'You are going to create a new task for each episode of the current project for the given task type. Do you want to continue?',
    create_tasks_episode_failed: 'A server error occurred while proceeding creations.',
    create_tasks_sequence: 'Add tasks for current sequences',
    create_tasks_sequence_explaination: 'You are going to create a new task for each sequence of the current project for the given task type. Do you want to continue?',
    create_tasks_sequence_failed: 'A server error occurred while proceeding creations.',
    current: 'Tasks',
    current_status: 'Current status:',
    delete_all_text: 'Are you sure you want to delete all tasks for given {name}? Please, confirm by typing the task type name of the tasks you want to delete in the text field.',
    delete_all_error: 'Deleting all tasks for given task type failed.',
    delete_error: 'An error occurred while deleting task.',
    delete_comment: 'Are you sure you want to delete comment?',
    delete_comment_error: 'An error occurred while deleting comment.',
    delete_for_selection: 'Delete the selected task | Delete the {nbSelectedTasks} selected tasks',
    delete_preview: 'Are you sure you want to delete this preview?',
    delete_preview_error: 'An error occurred while deleting preview.',
    done: 'Done',
    download_pdf_file: 'Download .{extension} file',
    due_date: 'Due date status',
    due_after_today: 'Due after today',
    due_before_today: 'Due before today',
    due_next_week: 'Due next week',
    due_next_month: 'Due next month',
    due_previous_week: 'Due previous week',
    due_previous_month: 'Due previous month',
    due_this_month: 'Due this month',
    due_this_week: 'Due this week',
    edit_comment: 'Edit comment',
    estimation_over: 'Duration over Estimation',
    estimation_approval_late: 'Due date is late (feedback)',
    estimation_done_late: 'Due date is late (done)',
    empty_cells_selected: 'empty cell selected | empty cells selected',
    feedback: 'feedback',
    full_screen: 'Display in full screen',
    for_selection: 'For current list and filters',
    for_project: 'For project',
    hide_assignations: 'Hide assignments',
    hide_infos: 'Hide additional information',
    late: 'Late status',
    my_checks: 'My Checks',
    my_tasks: 'My Tasks',
    nb_selected_tasks: '{nbSelectedTasks} task selected | {nbSelectedTasks} tasks selected',
    new_revision_number: 'New revision number',
    next: 'next task',
    no_assignation_right: 'You are not allowed to manage assignments',
    no_comment: 'There is currently no comment for this task.',
    no_preview: 'There is currently no preview for this task.',
    no_task_selected: 'No task selected',
    number: 'task | tasks',
    pending: 'Pending',
    post: 'Post',
    publish: 'Publish',
    publish_revision: 'Publish revision',
    preview: 'Previews',
    previous: 'previous task',
    revision_preview_file: 'If one of your selected files contains a revision number in its name (eg. "preview-v3.png"), then it will be suggested as a new revision name (eg. "revision 3") at the next step.',
    select_preview_file: 'Please select files (pictures, movies, or others) from your hard drive to be used as a new preview revision for the current task:',
    selected_entities: 'Selected entities',
    selected_tasks: 'Selected tasks',
    set_estimations: 'Set estimations for selected tasks:',
    set_preview: 'Set current preview as thumbnail',
    set_preview_error: 'An error occurred while setting preview as thumbnail',
    set_preview_done: 'This preview is used as the thumbnail for the current entity.',
    set_thumbnails_from_tasks: 'Set the last preview from the selected task as thumbnail | Set last previews from selected tasks as thumbnails',
    show_assignations: 'Show assignments',
    show_infos: 'Show additional information',
    small_thumbnails: 'Show small thumbnails',
    smaller: 'Reduce task panel',
    select_file: 'Please select the file from your hard drive you want to attach to your comment or message:',
    show_contact_sheet: 'Display tasks as a contact sheet',
    subscribe_notifications: 'Subscribe to notifications',
    subscribe_to_tasks: 'Subscribe to selected task notifications | Subscribe to the {nbSelectedTasks} selected tasks notifications',
    unsubscribe_notifications: 'Unsubscribe from notifications',
    tasks: 'Tasks',
    to_myself: 'To myself',
    use_current_frame: 'Use current frame',
    unassigned_tasks: 'Unassigned tasks',
    validated: 'Validated',
    validation: 'Validation',
    with_comment: 'With a comment...',
    fields: {
      asset_type: 'Asset type',
      assignees: 'Assignees',
      count: 'Count',
      due_date: 'Due date',
      duration: 'Duration',
      end_date: 'Feedback date',
      entity: 'Entity',
      entity_name: 'Name',
      estimated_quota: 'Quota',
      estimation: 'Estimation',
      frames: 'Fram.',
      last_comment: 'Last comment',
      last_comment_date: 'Last comment',
      parent: 'Parent',
      priority: 'Priority',
      production: 'Prod',
      real_end_date: 'Feedback date',
      real_start_date: 'WIP date',
      retake_count: 'Retakes',
      seconds: 'Seconds',
      sequence: 'Sequence',
      start_date: 'Start date',
      start_date_short: 'Start date',
      task_status: 'Status',
      task_status_short_name: 'Status',
      task_type: 'Type'
    },
    colors: {
      title: 'Coloring',
      neutral: 'Neutral',
      status: 'Status color',
      late: 'Late in red'
    },
    priority: {
      emergency: 'Emergency',
      normal: 'Normal',
      high: 'High',
      very_high: 'Very High'
    },
    combobox_departments: {
      all_departments: 'All Departments',
      my_departments: 'My Departments'
    }
  },

  preview: {
    broken: 'This preview is broken.',
    processing: 'Video processing in progress...'
  },

  search: {
    limit: 'Max number of results:',
    match_details: 'match found in {target}',
    placeholder: 'Search for an entity in the database...',
    title: 'Entity Search'
  },

  timesheets: {
    day_off: 'Day Off',
    detail_level: 'Detail level',
    done_tasks: 'Done tasks',
    export_timesheet: 'Export Timesheet',
    export_timespents: 'Export time spent entries for open projects as a list',
    hours: 'hours',
    month: 'Month',
    off: 'Off',
    time_spents: 'Time Spent (hours)',
    title: 'Timesheets',
    unit: 'Unit',
    year: 'Year'
  },

  days_off: {
    title: 'Days Off',
    period: 'Period',
    description: 'Description',
    updated_at: 'Update date',
    nb_days_off: 'Day Off | Days Off',
    no_day_off: 'No day off for the selected period.',
    add: 'Add day off',
    edit: 'Edit day off',
    delete: 'Delete day off',
    confirm_day_offs: 'Setting these days off will erase all time filled for the affected days. Are you sure you want to continue?',
    confirm_unset_day_offs: 'Days off are currently applied from {start} to {end}. Are you sure you want to remove this period?',
    error_days_off: 'An error occurred while updating days off.',
  },

  wrong_browser: {
    title: 'Your browser is not supported by Kitsu',
    text: 'Kitsu can only be used with Firefox and Chrome browsers.'
  }
}
